<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="header">
      <h1>智慧园区</h1>
      <p style="text-indent: 2em">
        未来智慧园区，融合新一代信息与通信技术，迅捷信息采集、高速信息传输、高度集中计算、智能事务处理和无所不在的服务提供能力，实现园区内及时、互动、整合的信息感知、传递和处理，以提高园区产业集聚能力、企业经济竞争力、园区可持续发展为目标的先进园区发展理念。
      </p>
    </div>
    <div class="framework">
      <h2 class="title">平台架构</h2>
      <div class="img3">
        <img
          style="width: 100%; height: 100%"
          @click="onYuLan(img)"
          :src="img"
          alt=""
        />
      </div>
    </div>
    <h2 class="title">功能场景</h2>
    <div class="sceneMore">
      <div class="sceneMoreImg">
        <div></div>
      </div>
      <div class="sceneMoreHeader">
        同步监测党建、安监、经济、治安、交通、环境等多维园区辖区常态领域运行
      </div>
      <div class="sceneMoreText">
        <div class="sceneMoreTextVer">
          <div class="sceneMoreTextCol">
            <div>立案率</div>
            <div>处置率</div>
            <div>按期处置率</div>
            <div>高发问题</div>
            <div>来源分析</div>
          </div>
          <div class="sceneMoreTextCol">
            <div>有效报案数</div>
            <div>警情分析</div>
            <div>园区刷卡数</div>
          </div>
          <div class="sceneMoreTextCol">
            <div>交通路况</div>
            <div>公共自行车</div>
            <div>停车泊位</div>
            <div>公交车</div>
            <div>地铁</div>
          </div>
        </div>
        <div class="sceneMoreTextVer">
          <div class="sceneMoreTextCol">
            <div>地区生产总值</div>
            <div>规上工业产值</div>
            <div>工业增加值</div>
            <div>固定资产投资</div>
          </div>
          <div class="sceneMoreTextCol">
            <div>AQI指数</div>
            <div>主要污染物</div>
            <div>PM2.5</div>
            <div>企业废水排放</div>
            <div>企业废气排放</div>
          </div>
          <div class="sceneMoreTextCol">
            <div>危险源</div>
            <div>防护目标</div>
            <div>避难场所</div>
            <div>物资装备</div>
            <div>应急值守</div>
          </div>
        </div>
      </div>
    </div>
    <div class="scene">
      <van-tabs
        v-model="active"
        swipeable
        :swipe-threshold="3"
        title-active-color="#365EF1"
        background="#F2F3F8"
      >
        <van-tab v-for="(item, index) in list" :key="index" :title="item.name">
          <img :src="item.img" alt="" class="sceneImg" />
          <h1 class="title">{{ item.name }}</h1>
          <div v-if="item.data">
            <div v-for="(el, i) in item.data" :key="i">
              <div class="classification">{{ el }}</div>
            </div>
          </div>
          <div v-if="item.text1">
            <p>{{ item.text1 }}</p>
            <p>{{ item.content }}</p>
          </div>
          <p v-else>{{ item.text }}</p>
          <div class="detail" @click="go(item.id)">查看详情</div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
import { ImagePreview } from "vant";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      img: require("../../assets/image/parkImg/组 255.png"),
      active: 0,
      list: [
        {
          id: 0,
          name: "云服务平台",
          img: require("../../assets/assets/parkImg/img3.png"),
          text: "在园区已建的有线、无线网络及园区数据中心的基础上，通过各子系统的数据整合，构建云办公、云商业、云管理等服务系统，提供完整的智慧园区应用。",
        },
        {
          id: 1,
          name: "能耗管理系统",
          img: require("../../assets/assets/parkImg/img4.png"),
          text: "对电、水、气、热（冷）量等集中采集与分析，对企业端各类能耗进行细分和统计，展示各类能源的使用消耗情况，通过精细化的管理找出高耗能点或不合理的耗能习惯，有效节约能源，为用户进一步节能改造或设备升级提供准确的数据支撑。",
        },
        {
          id: 2,
          name: "立体综合安防系统",
          img: require("../../assets/assets/parkImg/img5.png"),
          text1:
            "安防重点：开放性园区在室外形成网格化、无视角、无缝连接的监控（周界）。",
          content:
            "人脸识别：在园区出入口、核心受控区等地点形成更精细化的人员管理功能。",
        },
        {
          id: 3,
          name: "资产管理系统",
          img: require("../../assets/assets/parkImg/img6.png"),
          text: "对日常管理中的资产信息进行实时监控、记录和自动更新，同时采集人员信息，真正实现“帐、卡、物、人”相符；将原来分散的资产管理信息集中起来，组合成为一个整体的资产管理信息平台，从而避免因人为因素造成的信息失真引起管理效能的下降。",
        },
      ],
    };
  },

  methods: {
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
    go(id) {
      this.$router.push({
        path: "/m_parkDetail",
        query: {
          parkId: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  box-sizing: border-box;
  margin-top: -27px;
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 20px 0;
  }
  .header {
    height: 480px;
    background: url("../../assets/assets/parkImg/bg.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 10px;
    > h1 {
      font-size: 20px;
      font-weight: normal;
      color: #fff;
      padding-top: 100px;
      margin: 0;
    }
    > p {
      padding: 20px 10px 0 0px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .framework {
    .img3 {
      width: calc(100% - 20px);
      height: 410px;
      background: url("../../assets/image/parkImg/组 255.png") no-repeat;
      background-size: contain;
      margin: 0px 0 0 10px;
    }
  }
  .scene {
    width: calc(100% - 20px);
    margin-left: 10px;
    background-color: #fff;
    padding-bottom: 20px;

    .sceneImg {
      width: 100%;
      height: 180px;
      padding-top: 20px;
      background-color: #f2f3f8;
    }
    p {
      text-indent: 2em;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      padding: 0 10px;
    }
    .detail {
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid rgba(54, 94, 241, 0.5);
      border-radius: 36px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      opacity: 1;
      position: relative;
      left: 0;
      right: 0;

      margin: auto;
      margin-top: 20px;
    }
    h4 {
      font-size: 14px;
      text-align: center;
      color: #333;
    }
    .classification {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      text-align: center;
    }
  }
  .sceneMore {
    .sceneMoreImg {
      height: 282px;
      width: calc(100% - 20px);
      overflow-x: auto;
      margin-left: 10px;
      > div {
        height: 278px;
        width: 100%;
        background: url("../../assets/assets/parkImg/img2.png") no-repeat;
        background-size: 100%;
        box-sizing: border-box;
        padding-right: 900px;
      }
    }
    .sceneMoreHeader {
      width: calc(100% - 20px);
      height: 44px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      margin: 16px 10px;
    }

    .sceneMoreText {
      width: calc(100% - 20px);
      margin-left: 10px;
      margin-bottom: 16px;
      .sceneMoreTextVer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 160px;
        font-size: 14px;
        font-weight: normal;
        color: #6b6b6b;
        margin-top: 10px;
        .sceneMoreTextCol {
          width: 32%;
          height: 160px;
          background-color: #fff;
          text-align: center;
          > div {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
::v-deep .van-tabs__line {
  background-color: #365ef1;
}
</style>
